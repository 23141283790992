export function partition<T>(
  items: T[],
  predicate: (item: T) => boolean
): [matches: T[], mismatches: T[]] {
  const matches: T[] = [];
  const mismatches: T[] = [];
  for (const item of items) {
    if (predicate(item)) {
      matches.push(item);
    } else {
      mismatches.push(item);
    }
  }
  return [matches, mismatches];
}
