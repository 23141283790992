import { adItemSchema } from '@/resource/ad';
import { dataArray } from '@/resource/helpers';
import { get, post } from '@/util/api';
import z from 'zod';
import { AdItemType } from './ad';
import { authStorage } from './auth';

export function addFavorite(ad: AdItemType) {
  return post('/account/favorites/add', z.any(), z.any(), {
    ad_id: ad.id,
  });
}

export function removeFavorite(ad: AdItemType) {
  return post('/account/favorites/remove', z.any(), z.any(), {
    ad_id: ad.id,
  });
}

export async function getFavorites() {
  const auth = authStorage.get('auth', null);
  if (!auth) {
    return Promise.resolve([]);
  }

  const { data } = await get('/account/favorites', dataArray(adItemSchema));
  return data;
}
