import { IllustrationNameType } from '@/resource/settings';
import { cx } from '@/util/cx';
import { ReactNode } from 'react';
import styles from './InfoItem.module.scss';

type Props = {
  arrow?: 'left' | 'right';
  children: ReactNode;
  illustration: IllustrationNameType;
};

export function InfoItem({ arrow, children, illustration }: Props) {
  return (
    <div className={cx(styles.Item, arrow && styles[`arrow-${arrow}`])}>
      <picture>
        <img
          src={`/svg/illustration/nh_${illustration}.svg`}
          alt={illustration.replace('_', ' ')}
        />
      </picture>

      <div>{children}</div>
    </div>
  );
}
