import { ReactNode } from 'react';
import styles from './AdGrid.module.scss';

type Props = {
  children: ReactNode;
};

export function AdCtaGrid({ children }: Props) {
  return <div className={styles.Grid}>{children}</div>;
}
