import { AdCtaGrid } from '@/components/AdGrid';
import { AdCtaItem, AdItem } from '@/components/AdItem';
import { LinkButton } from '@/components/Button';
import { Flex, Gridish } from '@/components/Grid';
import { Heading } from '@/components/Heading';
import { InfoItem } from '@/components/InfoItem';
import { Section } from '@/components/Section';
import { Text } from '@/components/Text';
import { useWindowSize } from '@/hooks/useWindowSize';
import { useAccount } from '@/resource/account';
import { AdItemType, getAds } from '@/resource/ad';
import { PageRefType } from '@/resource/settings';
import { TABLET_LANDSCAPE, TABLET_PORTRAIT } from '@/util/breakpoints';
import { staticProps } from '@/util/staticProps';

type Props = {
  offers: AdItemType[];
  requests: AdItemType[];
  whatIsPage: PageRefType | null;
};

export default function Home({ offers, requests, whatIsPage }: Props) {
  const { profile } = useAccount();
  const { width } = useWindowSize();

  let count = 6;
  if (width >= TABLET_LANDSCAPE) {
    count = 8;
  } else if (width >= TABLET_PORTRAIT) {
    count = 9;
  }

  return (
    <>
      <Section>
        <Gridish minColumnWidth={{ sm: 340, lg: 440 }}>
          <InfoItem illustration="mannen_laptop">
            <Heading>Hulp aanbieden</Heading>
            <Text balance>
              Ondersteun anderen met jouw hulp. Als we dat allemaal doen, maken
              we met NH Helpt een hechte gemeenschap van Noord-Holland. Doe ook
              mee!
            </Text>
            {profile ? (
              <LinkButton align="center" to={{ type: 'add_offer' }}>
                Plaats aanbod
              </LinkButton>
            ) : (
              <LinkButton align="center" to={{ type: 'market_offers' }}>
                Bekijk alle aanbod
              </LinkButton>
            )}
          </InfoItem>
          <InfoItem illustration="vraag_hulp">
            <Heading>Hulp vragen</Heading>
            <Text balance>
              Een beetje hulp kunnen we allemaal weleens gebruiken. Aarzel niet
              en laat via NH Helpt weten wat je nodig hebt. Met een grote groep
              wordt het makkelijker toch? Sluit je aan!
            </Text>
            {profile ? (
              <LinkButton align="center" to={{ type: 'add_request' }}>
                Plaats vraag
              </LinkButton>
            ) : (
              <LinkButton align="center" to={{ type: 'market_requests' }}>
                Bekijk alle vragen
              </LinkButton>
            )}
          </InfoItem>
          <InfoItem arrow="left" illustration="blikjestelefoon">
            <Heading>Wat is NH Helpt?</Heading>
            <Text balance>
              NH Helpt is de plek waar Noord-Hollanders die naar elkaar omkijken
              elkaar vinden. Het biedt een plek voor het aanbieden en vinden van
              hulp in je eigen buurt, altijd kosteloos.
            </Text>
            {whatIsPage && (
              <LinkButton
                theme="secondary"
                to={{ type: 'info', page: whatIsPage }}
              >
                Lees meer
              </LinkButton>
            )}
          </InfoItem>
        </Gridish>
      </Section>

      <Section theme={4}>
        <h2>Ontdek wie hulp aanbiedt</h2>
        <AdCtaGrid>
          {offers.slice(0, count - 1).map((item) => (
            <AdItem item={item} key={item.id} theme={0} />
          ))}
          <AdCtaItem type="offer" to={{ type: 'add_offer' }} theme={0} />
        </AdCtaGrid>
        <Flex justify="center">
          <LinkButton to={{ type: 'market_offers' }}>Bekijk aanbod</LinkButton>
        </Flex>
      </Section>

      <Section>
        <h2>Ontdek wie jij kan helpen</h2>
        <AdCtaGrid>
          {requests.slice(0, count - 1).map((item) => (
            <AdItem item={item} key={item.id} />
          ))}
          <AdCtaItem type="request" to={{ type: 'add_request' }} />
        </AdCtaGrid>
        <Flex justify="center">
          <LinkButton to={{ type: 'market_requests' }}>
            Bekijk alle vragen
          </LinkButton>
        </Flex>
      </Section>
    </>
  );
}

export const getStaticProps = staticProps.resolve<Props>(
  async (cxt, settings) => {
    const limit = 9;
    const sort = 'recent';
    const [offers, requests] = await Promise.all([
      getAds({
        type: 'offer',
        limit,
        sort,
        statuses: ['approved', 'update_review'],
      }),
      getAds({
        type: 'request',
        limit,
        sort,
        statuses: ['approved', 'update_review'],
      }),
    ]);

    return {
      props: {
        offers: offers.data,
        requests: requests.data,
        whatIsPage: settings.what_is_page?.data || null,
      },
      revalidate: 30,
    };
  }
);
