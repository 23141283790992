import { AdItem } from '@/components/AdItem';
import { AdItemType } from '@/resource/ad';
import styles from './AdGrid.module.scss';

type Props = {
  items: AdItemType[];
  filterCategoryIds?: number[];
};

export function AdGrid({ items, filterCategoryIds = [] }: Props) {
  if (items.length === 0) {
    return null;
  }

  return (
    <div className={styles.Grid}>
      {items.map((item) => (
        <AdItem
          item={item}
          key={item.id}
          filterCategoryIds={filterCategoryIds}
        />
      ))}
    </div>
  );
}
