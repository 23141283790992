import { ReactNode } from 'react';
import { cx } from '@/util/cx';
import styles from './Section.module.scss';

type Props = {
  children: ReactNode;
  theme?: 1 | 2 | 3 | 4 | 5;
};

export function Section({ children, theme }: Props) {
  return (
    <section
      className={cx(styles.Section, theme && styles[`theme-${[theme]}`])}
    >
      <div>{children}</div>
    </section>
  );
}
